<nav class="navbar" [ngClass]="isFixed ? 'fixed' : ''">
  <div class="main-container">
    <div class="navbar__container">
      <a routerLink="/" class="navbar__logo-link"><img src="/assets/main/logo.png" alt=""
          class="navbar__logo-image"></a>
      <div class="navbar__nav">
        <ul class="navbar__list" id="navbar" [class.open]="menuOpened">
          <li class="navbar__item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a routerLink="/" class="navbar__link">Home</a>
          </li>
          <li class="navbar__item" routerLinkActive="active">
            <a routerLink="/portfolio" class="navbar__link">Portfolio</a>
          </li>
          <li class="navbar__item" routerLinkActive="active">
            <div id="dropdown" (click)="dropdownOpened = !dropdownOpened" class="navbar__link">Examples
              <b class="caret" [class.rotate]="dropdownOpened">&#x25B2;</b>
            </div>
            <div class="navbar__dropdown-menu" [class.open]="dropdownOpened">
              <a routerLink="/examples/landing/grandmas" class="navbar__dropdown-link"
                routerLinkActive="active">Grandma's
                Sweets</a>
              <a routerLink="/examples/landing/car" class="navbar__dropdown-link" routerLinkActive="active">Car
                Dealership</a>
              <a routerLink="/examples/landing/looplab" class="navbar__dropdown-link"
                routerLinkActive="active">LoopLAB</a>
              <a routerLink="/examples/landing/sushi" class="navbar__dropdown-link"
                 routerLinkActive="active">Sushi</a>
            </div>
          </li>
        </ul>
      </div>
      <button id="menu-button" #navButton class="navbar__button" (click)="toggleMenu()"><i
          class="fas fa-bars"></i></button>
    </div>

  </div>
</nav>
