import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAsterisk,
  faThermometer,
  faLocationArrow,
  faDesktop,
  faCube,
  faBuilding,
  faUser,
  faBed,
  faLink,
  faTabletAlt,
  faUserFriends,
  faPhoneVolume,
  faKey,
  faSearch,
  faArrowDown,
  faPhone,
  faEnvelope,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  FaConfig,
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import {
  faFacebook,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faYelp,
} from "@fortawesome/free-brands-svg-icons";
import { faHandPointRight } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class CommonFontAwesomeModule {
  constructor(library: FaIconLibrary, options: FaConfig) {
    library.addIcons(
      faAsterisk,
      faThermometer,
      faLocationArrow,
      faDesktop,
      faCube,
      faBuilding,
      faUser,
      faBed,
      faLink,
      faTabletAlt,
      faUserFriends,
      faPhoneVolume,
      faKey,
      faSearch,
      faTwitter,
      faFacebookSquare,
      faInstagram,
      faYelp,
      faArrowDown,
      faPhone,
      faEnvelope,
      faHandPointRight,
      faArrowUp
    );

    options.fixedWidth = true;
  }
}
