import { Injectable } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class BusyService {
  busyRequestCount = 0;

  constructor(private spinner: NgxSpinnerService) { }

  busy(): void {
    this.busyRequestCount++;
    this.spinner.show(undefined, {
      type: 'ball-clip-rotate-multiple',
      bdColor: 'rgba(255,255,255,.7)',
      color: '#333333'
    });
  }

  idle(): void {
    this.busyRequestCount--;
    this.spinner.hide();
  }
}
