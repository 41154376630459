import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutes } from "./routes";
import { NotfoundComponent } from "./notfound/notfound.component";

import { NavComponent } from "./main/shared/components/nav/nav.component";
import { FooterComponent } from "./main/shared/components/footer/footer.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoadingInterceptor } from "./main/shared/interceptors/loading-interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonFontAwesomeModule } from "./main/shared/modules/font-awesome.module";

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent,
    NavComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      initialNavigation: "enabled",
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
     onSameUrlNavigation: 'reload'
    }),
    HttpClientModule,
    NgxSpinnerModule,
    CommonFontAwesomeModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    NavComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
