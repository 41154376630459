import { Component, OnInit, HostListener, Inject, PLATFORM_ID, OnChanges, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'main-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  isFixed: boolean;
  pageHeight: number;
  dropdownOpened: boolean;
  menuOpened: boolean;
  @Output() menuOpen = new EventEmitter<boolean>();
  @ViewChild('navButton') navButton: ElementRef<HTMLButtonElement>;

  @HostListener('window:resize', ['$event'])
  onWindowResize(e: any) {
    this.pageHeight = (e.target.innerHeight);
  }

  @HostListener('window:scroll', [])
  onPageScroll() {
    if (this.route.url === '/') {
      if (window.pageYOffset >= this.pageHeight) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }

    }
  }

  @HostListener('document:click', ['$event'])
  onClick(e: any) {
    if (e.target.id !== 'dropdown') {
      this.dropdownOpened = false;
    }

    if (!this.navButton.nativeElement.contains(e.target) && !this.dropdownOpened) {
      this.menuOpened = false;
      this.menuOpen.emit(false);
    }
  }

  constructor(private route: Router, @Inject(PLATFORM_ID) private platformId: any, private eRef: ElementRef) {
    route.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/') {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.pageHeight = (window as any).innerHeight;
    }
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
    this.menuOpen.emit(this.menuOpened);
  }
}
