import { Routes } from "@angular/router";
import { NotfoundComponent } from "./notfound/notfound.component";

export const AppRoutes: Routes = [
  {
    path: "",
    loadChildren: () => import("./main/main.module").then((m) => m.MainModule),
  },
  {
    path: "examples/landing",
    children: [
      {
        path: "grandmas",
        loadChildren: () =>
          import("./examples/landing/grandmas/grandmas.module").then(
            (m) => m.GrandmasModule
          ),
      },
      {
        path: "car",
        loadChildren: () =>
          import("./examples/landing/car/car.module").then((m) => m.CarModule),
      },
      {
        path: "looplab",
        loadChildren: () =>
          import("./examples/landing/looplab/looplab.module").then(
            (m) => m.LooplabModule
          ),
      },
      {
        path: "sushi",
        loadChildren: () =>
          import("./examples/landing/sushi/sushi.module").then(
            (m) => m.SushiModule
          ),
      },
    ],
  },
  {
    path: "**",
    component: NotfoundComponent,
  },
];
