<ngx-spinner>
  <h3>Loading...</h3>
</ngx-spinner>

<div [class.overlay]="menuOpen"></div>
<main-nav (menuOpen)="toggleMenu($event)"></main-nav>
<div class="outlet" [ngClass]="isHome ? 'home' : ''" [class.menu]="menuOpen">
  <router-outlet></router-outlet>
</div>
<main-footer></main-footer>
