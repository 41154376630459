import { Component, AfterContentChecked, AfterViewInit, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, NavigationStart, NavigationCancel } from '@angular/router';
import { BusyService } from './main/shared/services/busy.service';
import { NavComponent } from './main/shared/components/nav/nav.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isHome: boolean;
  menuOpen: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: any, route: Router, private busy: BusyService) {


    route.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
      if (e instanceof NavigationEnd) {
        if (e.url !== '/') {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      }
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.busy.busy();
    }
    if (event instanceof NavigationEnd) {
      this.busy.idle();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.busy.idle();
    }
    if (event instanceof NavigationEnd) {
      this.busy.idle();
    }
  }

  toggleMenu(event: boolean) {
    this.menuOpen = event;
    if (isPlatformBrowser(this.platformId)) {
      if (event) {
        document.getElementById('body').classList.add('noscroll');
      } else {
        document.getElementById('body').classList.remove('noscroll');
      }
    }
  }
}
